<template>
  <div id="editor"></div>
</template>

<script>
//wangEditor官网地址： https://www.wangeditor.com/
import E from "wangeditor";
import Axios from "axios";
export default {
  props: {
    //编辑器高度：单位px
    height: {
      type: [String, Number],
      default: 500,
    },
    placeholder: {
      type: String,
      default: "请输入内容",
    },
  },
  data() {
    return {
      editor: null,
    };
  },
  created() { },
  mounted() {
    const editor = new E("#editor");
    let that = this;
    editor.config.uploadImgServer =
      process.env.VUE_APP_API + "/api/common/fileInfo/newUpload";
    editor.config.uploadImgMaxLength = 6; // 设置图片最大上传数量为6
    editor.config.uploadFileName = "file";
    editor.config.uploadImgMaxSize = 5 * 1024 * 1024;
    editor.config.uploadImgTimeout = 20000;
    editor.config.pasteFilterStyle = false;

    // 上传视频
    // 配置 server 接口地址
    editor.config.uploadVideoServer =
      process.env.VUE_APP_API + "/api/common/fileInfo/uploadImg";
    editor.config.uploadVideoName = "file";
    editor.config.uploadVideoHooks = {
      // 视频上传并返回了结果，视频插入已成功
      success: function (xhr) {
        console.log("success", xhr);
      },
      // 视频上传并返回了结果，但视频插入时出错了
      fail: function (xhr, editor, resData) {
        console.log("fail", resData);
      },
      // 上传视频出错，一般为 http 请求的错误
      error: function (xhr, editor, resData) {
        console.log("error", xhr, resData);
      },
      // 上传视频超时
      timeout: function (xhr) {
        console.log("timeout");
      },
      // 视频上传并返回了结果，想要自己把视频插入到编辑器中
      // 例如服务器端返回的不是 { errno: 0, data: { url : '.....'} } 这种格式，可使用 customInsert
      customInsert: function (insertVideoFn, result) {
        // result 即服务端返回的接口
        // insertVideoFn 可把视频插入到编辑器，传入视频 src ，执行函数即可
        insertVideoFn(result.data.fileUrl);
      },
    };

    // 自定义检查插入视频的回调

    editor.config.onlineVideoCallback = function (video) {
      // 自定义回调内容，内容成功插入后会执行该函数
      that.setHtml(`<video src="${video}" id='target' width='750' controls />`);
    };

    // 编辑器的事件，每次改变会获取其html内容（html内容是带标签的）
    // editor.config.uploadImgHeaders = {
    //   Authorization: help.cookie.getItem("token"),
    // };
    // this.editor.config.height = this.height;
    // this.editor.config.placeholder = this.placeholder;
    // this.editor.config.onchange = this.onChange;
    // this.editor.config.onblur=this.onBlur;
    // this.editor.config.onfocus=this.onFocus;
    // this.editor.config.showLinkImg = false;  //隐藏网络图片

    // #editor>.w-e-text-container>#w-e-text{
    //   display:grid;
    // }
    // #editor>.w-e-text-container>#w-e-text>.w-e-text>p{
    // display:grid;
    // }
    // 配置 onchange 回调函数
    // <style  >#editor .w-e-text-container *{display: block;}</style>
    editor.config.onchange = function (newHtml) {
      document
        .querySelectorAll("#editor .w-e-text-container p")
        .forEach(function (box) {
          box.style.display = "block";
          box.style.maxWidth = "100%";
        });

      document
        .querySelectorAll("#editor .w-e-text-container div")
        .forEach(function (box) {
          box.style.display = "block";
          box.style.maxWidth = "100%";
        });
      document
        .querySelectorAll("#editor .w-e-text-container video")
        .forEach(function (box) {
          box.classList.add("video");
        });

      if (
        document.querySelectorAll("#editor .w-e-text-container img") != null
      ) {
        document
          .querySelectorAll("#editor .w-e-text-container img")
          .forEach(function (box) {
            box.style.display = "block";
            box.style.maxWidth = "100%";
            box.classList.add("img");
          });
      }

      document.querySelectorAll("#editor .placeholder").forEach(function (box) {
        box.style.display = "none";
      });
    };

    // 配置触发 onchange 的时间频率，默认为 200ms
    editor.config.onchangeTimeout = 500; // 修改为 500ms

    editor.create();
    let _that = this;
    // 自定义上传图片
    editor.config.customUploadImg = async function (files, insert) {
      // resultFiles 是 input 中选中的文件列表
      // insertImgFn 是获取图片 url 后，插入到编辑器的方法
      var daw = new FormData();
      for (var i = 0; i < files.length; i++) {
        daw.append("files", files[i]);
      }
      const config = {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      };
      const uploadUrl = `${process.env.VUE_APP_API}/api/common/fileInfo/newUpload`;
      // _that.filesToBase64(resultFiles);
      const res = await Axios.post(uploadUrl, daw, config)
      if (res.data.success) {
        for (var j = 0; j < res.data.data.length; j++) {
          insert(res.data.data[j].fileUrl);
        }
      } else {
        _this.$message({
          message: "文件服务异常，请联系管理员！",
          type: "error",
        });
      }
    };

    this.editor = editor;
    // 获取 html
  },
  methods: {
    //追加文本信息
    setHtml(e) {
      this.editor.txt.append(e);
      // this.editor.txt.html(e)
      this.$nextTick(() => {
        var targetElement = document.getElementById("target");
        var previousElement = targetElement?.previousElementSibling;

        if (previousElement && previousElement.textContent.trim() !== "") {
          previousElement.parentNode.removeChild(previousElement);
        }
      });
    },
    getHtml() {
      return this.editor.txt.html();
    },
    //   转base64
    filesToBase64(files) {
      const _this = this;
      console.log(files);
      const imgOrder = []
      for (let i = 0; i < files.length; i++) {
        const file = files[i]
        let reader = new FileReader();
        imgOrder.push(file.name)
        reader.onload = function (e) {
          _this.uploadImg(e.target.result, file);
        };
        reader.readAsDataURL(file);
      }
      // files.map((item) => {
      //   var reader = new FileReader();
      //   reader.onload = async function (e) {
      //     await _this.uploadImg(e.target.result, item);
      //   };
      //   // 传入一个参数对象即可得到基于该参数对象的文本内容
      //   reader.readAsDataURL(item);
      // });
    },
    // 上传图片
    async uploadImg(base64, file) {
      const _this = this;
      // 自己的上传地址
      const uploadUrl = `${process.env.VUE_APP_API}/api/common/fileInfo/newUpload`;
      const formData = new FormData();
      this.Base64toBlob({
        base64,
        async success(blob) {
          formData.append("files", blob, file.name);
          // 调用axios上传
          const config = {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          };
          const res = await Axios.post(uploadUrl, formData, config)
          // 根据自己的后台逻辑进行判断
          if (res.data.success) {
            // 上传图片，返回结果，将图片插入到编辑器中
            _this.editor.cmd.do(
              "insertHtml",
              '<img src="' +
              res.data.data[0].fileUrl +
              '" style="max-width:100%;"/>'
            );
          } else {
            _this.$message({
              message: "文件服务异常，请联系管理员！",
              type: "error",
            });
          }

        },
      });
    },
    // base64转blob
    Base64toBlob({ base64, success }) {
      var arr = base64.split(",");
      var mime = arr[0].match(/:(.*?);/)[1];
      var bstr = atob(arr[1]);
      var n = bstr.length;
      var u8arr = new Uint8Array(n);
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }
      const blob = new Blob([u8arr], { type: mime });
      success(blob);
    },
  },
};
</script>

<style>
#editor .w-e-text-container * {
  display: block;
}
</style>
